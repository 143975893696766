<template>
  <AuthWrapper>
    <div class="auth-contents">
      <CardWrapper>
        <div class="card-body" align="center">
          <h4 class="card-body__title">
            <img :src="theme['image-large-logo']" alt=""/>
          </h4>
          <div class="card-short__content">
            <a-form @finish="handleSubmit" :model="form" :rules="rules" layout="vertical">
              <a-form-item name="password" label="Kies een wachtwoord">
                <a-input type="password" v-model:value="form.password"/>
              </a-form-item>
              <a-form-item name="passwordConfirm" label="Bevestig wachtwoord">
                <a-input
                    type="password"
                    v-model:value="form.passwordConfirm"
                    placeholder="Password"
                />
              </a-form-item>
              <a-form-item>
              <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="isLoading"
                        :disabled="isLoading"> {{ isLoading ? "Laden..." : "Opslaan" }}
              </a-button>
              </a-form-item>
              <div class="auth-form-action text-center w-100" style="justify-content: center;">
                <router-link class="forgot-pass-link" to="/auth/login">
                  <sdFeatherIcons type="arrow-left-circle" size="16"/> Terug
                </router-link>
              </div>
            </a-form>
          </div>
        </div>
      </CardWrapper>
    </div>
  </AuthWrapper>
</template>
<script>
import {computed, defineComponent, reactive, ref} from "vue";
import {useStore} from "vuex";
import {AuthWrapper, CardWrapper} from "./style";
import equals from "validator/lib/equals";
import {theme} from '../../config/theme/themeVariables'

const {useRoute} = require("vue-router");

const SignIn = defineComponent({
  name: "SignIn",
  components: {AuthWrapper, CardWrapper},
  methods: {
    loginRoute() {
      this.$router.push('/');
    },
    handleSubmit() {
      this.$store.dispatch("setPassword", {form: this.form, loginRoute: this.loginRoute})
    }
  },
  setup() {
    const {state} = useStore();
    const router = useRoute();
    const isLoading = computed(() => state.auth.loadingSetPassword);
    const checked = ref(null);

    const onChange = (checked) => {
      checked.value = checked;
    };
    const form = reactive({
      password: '',
      passwordConfirm: '',
      token: router.query.token,
    });

    const rules = {
      password: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      passwordConfirm: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };

    return {
      isLoading,
      checked,
      onChange,
      form,
      rules,
      equals,
      theme
    };
  },
});

export default SignIn;
</script>
<style>
.ant-form-item-explain.ant-form-item-explain-error {
  text-align: left;
}
</style>
